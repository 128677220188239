<template>
<div>

   <div class="table-page-search-wrapper">
      <a-form layout="inline" class="formInquire">
        <a-row :gutter="48" class="orderTop">
          <a-col :md="7" :sm="12">
            <a-form-item label="省份">
              <a-select @change="selectProvince($event)" v-model="queryParam.provinceCode" placeholder="省份" style="width: 80%">
                <a-select-option :key="item.value" v-for="item in provinceData" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="12">
            <a-form-item label="城市">
              <a-select @change="selectCity($event)" v-model="queryParam.cityCode" placeholder="城市" style="width: 80%">
                <a-select-option :key="item.value" v-for="item in cityData" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

         <a-col :md="7" :sm="12">
            <a-form-item label="区县">
              <a-select v-model="queryParam.districtCode" placeholder="区县" style="width: 80%">
                <a-select-option :key="item.value" v-for="item in districtData" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

           <a-col :md="7" :sm="12">
            <a-form-item label="网点名称">
              <a-input v-model="queryParam.outletsName" placeholder="网点名称" style="width: 80%" />
            </a-form-item>
          </a-col>
               
        </a-row>
      
        <a-row :gutter="48">
            <a-col>
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.pageTable.refresh(true)">查询</a-button>
              <a-button style="margin-left: 10px" @click="() => (queryParam = {},cityData={},districtData={})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

  <div class="table-operator" style="margin-bottom:15px">
    <div>
    <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="margin-right:15px;display:inline-block;">
      <a-button type="primary" icon="upload">上传派费表</a-button>
    </a-upload>
    <a-button icon="download" @click="downloadTemplate()">下载表头</a-button>
    </div>
    <a-button type="primary" icon="download" @click="downloadExcel()" style="float:right">下载派费表</a-button>
  </div>

  <s-table ref="pageTable"
    :rowKey="(record) => record.id"
    :columns="columns"
    :data="dataset"
    :pagination="pagination"
    :pageSize="10"
    :scroll="{x: 'max-content'}"
  >
    <a slot="name" slot-scope="text">{{ text }}</a>
    <span slot="operation" slot-scope="text, record">
      <a-popconfirm title="您确认要删除此条记录吗？" ok-text="确认" cancel-text="取消" @confirm="handleRemove(record)">
        <a>删除</a>
      </a-popconfirm>
    </span>
  </s-table>

  <a-modal
    key="upload"
    title="上传报价表"
    :width="900"
    :footer="null"
    :centered="true"
    :keyboard="false"
    :maskClosable="false"
    :visible="modalUploadVisible"
    @cancel="modalUploadCancel"
  >
    <a-table ref="modalTable" size="middle"
      :rowKey="(record) => record.idx"
      :columns="columns"
      :data-source="modalUploadDataset"
      :scroll="{x: 'max-content'}"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
    </a-table>
    <a-button style="margin:20px 0 0 92%" type="primary" htmlType="submit" :loading="buttonLoading.importSave" @click="modalUploadSubmit()">保存</a-button>
  </a-modal>

</div>
</template>
<script>
import { STable } from '@/components'
import { dispatchList, dispatchInsert, dispatchRemove, documentInfo, geoCascaderList } from '@/api/quotation'
import { ossFileUrl } from '@/utils/upload'
import XLSX from 'xlsx'

const columns = [
  {
    key: 'provinceName',
    dataIndex: 'provinceName',
    title: '省份',
    align: 'center',
    fixed: 'left',
    width: 80 ,
  },
  {
    key: 'cityName',
    dataIndex: 'cityName',
    title: '城市',
    align: 'center',
    fixed: 'left',
    width: 80 ,
  },
  {
    key: 'districtName',
    dataIndex: 'districtName',
    title: '区县',
    align: 'center',
    fixed: 'left',
    width: 80 ,
  },
  {
    key: 'outletsName',
    dataIndex: 'outletsName',
    title: '网点名称',
    align: 'center',
    fixed: 'left',
    width: 120 ,
  },
  {
    key: 'range1FirstWeightPrice',
    dataIndex: 'range1FirstWeightPrice',
    title: '首重10KG',
    align: 'center',
  },
  {
    key: 'range1ContinuedWeightPrice',
    dataIndex: 'range1ContinuedWeightPrice',
    title: '超10KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'range2FirstWeightPrice',
    dataIndex: 'range2FirstWeightPrice',
    title: '首重100KG',
    align: 'center',
  },
  {
    key: 'range2ContinuedWeightPrice',
    dataIndex: 'range2ContinuedWeightPrice',
    title: '超100KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'range3FirstWeightPrice',
    dataIndex: 'range3FirstWeightPrice',
    title: '首重300KG',
    align: 'center',
  },
  {
    key: 'range3ContinuedWeightPrice',
    dataIndex: 'range3ContinuedWeightPrice',
    title: '超300KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'range4FirstWeightPrice',
    dataIndex: 'range4FirstWeightPrice',
    title: '首重500KG',
    align: 'center',
  },
  {
    key: 'range4ContinuedWeightPrice',
    dataIndex: 'range4ContinuedWeightPrice',
    title: '超500KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'range5FirstWeightPrice',
    dataIndex: 'range5FirstWeightPrice',
    title: '首重1200KG',
    align: 'center',
  },
  {
    key: 'range5ContinuedWeightPrice',
    dataIndex: 'range5ContinuedWeightPrice',
    title: '超1200KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'upperLimitPrice',
    dataIndex: 'upperLimitPrice',
    title: '派费上限',
    align: 'center',
  },
  {
    key: 'operation',
    dataIndex: 'operation',
    title: '操作',
    fixed:'right',
    align: 'center',
    width: '100px',
    scopedSlots: {
      customRender: 'operation',
    },
  },
];

const excelConfig = {
  "cityName": "城市",
  "districtName": "区域",
  "outletsName": "网点名称",
  "range1FirstWeightPrice": "首重10KG",
  "range1ContinuedWeightPrice": "超10KG续重（元/KG）",
  "range2FirstWeightPrice": "首重100KG",
  "range2ContinuedWeightPrice": "超100KG续重（元/KG）",
  "range3FirstWeightPrice": "首重300KG",
  "range3ContinuedWeightPrice": "超300KG续重（元/KG）",
  "range4FirstWeightPrice": "首重500KG",
  "range4ContinuedWeightPrice": "超500KG续重（元/KG）",
  "range5FirstWeightPrice": "首重1200KG",
  "range5ContinuedWeightPrice": "超1200KG续重（元/KG）",
  "upperLimitPrice": "派费上线",
};

export default {
  name: 'TableList',
  components: {
    STable,
    XLSX,
  },
  data() {
    return {
      columns: columns,
      dataset: parameter => {
        return dispatchList(Object.assign(parameter, this.queryParam))
          .then(res => {
            if (0 == res.code) {
              return res.result;
            }
          }).catch(e => {
            console.info(e);
            this.$message.error('数据加载失败，请稍后重试');
          });
      },
      pagination: { pageSizeOptions: ['10', '20', '50'] },
      queryParam: {},
      provinceData:{},
      cityData:{},
      districtData:{},
      provinceCode:"",
      modalUploadVisible: false,
      modalUploadDataset: [],
      geoCascaderList: [],
      downloadExcelState: 0,
      downloadExcelDataset: [],
      buttonLoading: {
        importSave: false,
      },
    };
  },
  mounted() {
    const parameter = {showDistrict: 1};
    geoCascaderList(parameter)
      .then(res => {
        if (0 == res.code) {
          this.geoCascaderList = res.result;
          this.provinceData = this.geoCascaderList
        }
      }).catch(e => {
        console.info(e)
      })
  },
  methods: {
    beforeUpload(file){
      let that = this;
      let reader = new FileReader();
      that.modalUploadDataset = [];
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
          let workbook = XLSX.read(e.target.result, {type: 'binary'});
          let worksheet = workbook.Sheets[workbook.SheetNames[0]];
          let result = XLSX.utils.sheet_to_json(worksheet);
          console.log(["xlxs-sheet", result])
          let dateset = [];
          for (let i = 0; i < result.length; i++) {
            let row = {"idx": i};
            let importState = true
            for (let key in excelConfig) {
              let name = excelConfig[key];
              let value = result[i][name];
              if (key == "cityName") {
                let districtName = excelConfig["districtName"];
                let districtValue = result[i][districtName];
                let geoInfo = this.geoSearchByName(value, districtValue);
                if (geoInfo == null) {
                  importState = false;
                  console.log(["xlxs-break", key, value, districtValue])
                  break;
                }
                row["provinceCode"] = geoInfo.provinceCode;
                row["provinceName"] = geoInfo.provinceName;
                row["cityCode"] = geoInfo.cityCode;
                row["cityName"] = geoInfo.cityName;
                row["districtCode"] = geoInfo.districtCode;
                row["districtName"] = geoInfo.districtName;
              }
              else if (key == "districtName") {
                continue;
              }
              else {
                row[key] = value;
              }
              if (typeof(value) == 'undefined' || (typeof(value) == 'string' && value.trim() == "")) {
                importState = false;
                console.log(["xlxs-break", key, value])
                break;
              }
            }
            if (importState && Object.keys(row).length > 1) {
              dateset.push(row);
            }
          }
          that.modalUploadDataset = dateset;
          that.modalUploadLoad();
      };
      return false;
    },
    geoSearchByName(cityName, districtName){
      for (let i = 0; i < this.geoCascaderList.length; i++) {
        let provinceInfo = this.geoCascaderList[i];
        let cityList = provinceInfo.children;
        for (let j = 0; j < cityList.length; j++) {
          let cityInfo = cityList[j];
          let districtList = cityInfo.children;
          if (cityInfo.label.indexOf(cityName) !== -1) {
            for (let k = 0; k < districtList.length; k++) {
              let districtInfo = districtList[k];
              if (districtInfo.label.indexOf(districtName) !== -1) {
                return {
                  "provinceCode": provinceInfo.value,
                  "provinceName": provinceInfo.label,
                  "cityCode": cityInfo.value,
                  "cityName": cityInfo.label,
                  "districtCode": districtInfo.value,
                  "districtName": districtInfo.label,
                }
              }
            }
          }
        }
      }
      return null;
    },
    modalUploadLoad(){
      this.modalUploadVisible = true;
      console.log(this.modalUploadDataset)
    },
    modalUploadCancel(){
      this.modalUploadVisible = false;
    },
    modalUploadSubmit(e){
      let parameter = {"recordList": this.modalUploadDataset};
      this.buttonLoading.importSave = true;
      dispatchInsert(parameter)
        .then(res => {
          if (0 == res.code) {
            this.$message.success('保存成功');
            this.modalUploadVisible = false;
            this.$refs.pageTable.refresh(true);
          } else {
            this.$message.error('保存失败');
          }
          this.buttonLoading.importSave = false;
        }).catch(e => {
          console.info(e);
          this.buttonLoading.importSave = false;
          this.$message.error('保存失败，请稍后重试');
        })
    },
    handleRemove(record){
      dispatchRemove(record)
        .then(res => {
          if (0 == res.code) {
            this.$message.success('删除成功');
            this.$refs.pageTable.refresh(true);
          } else {
            this.$message.error('删除失败');
          }
        }).catch(e => {
          console.info(e);
          this.$message.error('删除失败，请稍后重试');
        })
    },
    downloadTemplate(){
      let parameter = {"type": 103};
      documentInfo(parameter)
        .then(res => {
          if (0 == res.code) {
            window.location.href = ossFileUrl(res.result.document);
          } else {
            this.$message.error('下载失败');
          }
        }).catch(e => {
          console.info(e);
          this.$message.error('下载失败，请稍后重试');
        });
    },
    downloadExcel(){
      if (this.downloadExcelState > 0) {
        return;
      }
      this.downloadExcelState = 1;
      this.downloadExcelDataset = [];

      let downloadExcelTitle = [];
      for (let key in excelConfig) {
        downloadExcelTitle.push(excelConfig[key]);
      }
      this.downloadExcelDataset.push(downloadExcelTitle);

      let parameter = {pageNo: 1, pageSize: 100};
      this.downloadDataset(parameter);
    },
    downloadDataset(parameter) {
      let that = this;
      dispatchList(Object.assign(parameter, this.queryParam))
        .then(res => {
          if (0 == res.code) {
            console.log(res.result);
            const result = res.result.result;
            for (let i = 0; i < result.length; i++) {
              const dataset = [
                result[i].cityName,
                result[i].districtName,
                result[i].outletsName,
                result[i].range1FirstWeightPrice,
                result[i].range1ContinuedWeightPrice,
                result[i].range2FirstWeightPrice,
                result[i].range2ContinuedWeightPrice,
                result[i].range3FirstWeightPrice,
                result[i].range3ContinuedWeightPrice,
                result[i].range4FirstWeightPrice,
                result[i].range4ContinuedWeightPrice,
                result[i].range5FirstWeightPrice,
                result[i].range5ContinuedWeightPrice,
                result[i].upperLimitPrice,
              ];
              that.downloadExcelDataset.push(dataset);
            }
            const pageNo = res.result.pageNo;
            const pageSize = res.result.pageSize;
            const totalCount = res.result.totalCount;
            const totalPage = Math.ceil(totalCount / pageSize);
            if (pageNo >= totalPage) {
              console.log(that.downloadExcelDataset);
              let sheet = XLSX.utils.aoa_to_sheet(that.downloadExcelDataset);
              let book = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(book, sheet, '派费表');
              XLSX.writeFile(book, '派费表.xls');
              that.downloadExcelState = 0
              return;
            }
            parameter.pageNo = pageNo + 1;
            that.downloadDataset(parameter);
          }
        }).catch(e => {
          console.info(e);
          that.downloadExcelState = 0
          that.$message.error('下载失败，请稍后重试');
        });
    },
    selectProvince(provinceCode){  
      this.queryParam = {provinceCode:provinceCode}
      this.provinceCode = provinceCode
      for(let i = 0; i < this.geoCascaderList.length; i++) {            
            if(provinceCode == this.geoCascaderList[i].value){                  
                  this.cityData = this.geoCascaderList[i].children                                         
                  break;
            }
      }
    },
    selectCity(cityCode){
        console.log('cityCode',cityCode)
        this.queryParam = {provinceCode:this.provinceCode,cityCode:cityCode}        
        for(let i = 0; i < this.cityData.length; i++) {            
            if(cityCode == this.cityData[i].value){                  
                  this.districtData = this.cityData[i].children                                         
                  break;
            }
       }
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-table-tbody{
  background-color: #fff;
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
.table-operator{
  display: flex;
  justify-content: space-between;
  > div{
    display: flex;
    >span:nth-child(1){
    display: flex !important;
    /deep/ .ant-upload-list-text {
      background-color: #fff;
      margin-left: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      .ant-upload-list-item{
        margin-top: 0 !important;
        }
      }
    }
  }
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
.table-operator{
  /deep/  .ant-upload-list-item{
    margin-top: 5px !important;
    .ant-upload-list-item-info .ant-upload-list-item-name{
      padding-right:10px;
    }
  }
  /deep/ .anticon-close{
    font-size: 15px !important;
  }
}
</style>
